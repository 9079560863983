import React from 'react';
import { Dropdown, Menu, Button, Tooltip } from 'antd';
import { CSVLink } from 'react-csv';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Document, Packer, Paragraph, Table, TableCell, TableRow } from 'docx';


const ExportDropdown = ({ filename, exportColumns, data }) => {
    const currentDate = getCurrentDate();

    const handleExcelExport = () => {
        const headers = exportColumns.map(col => col.label);
        const excelData = data.map(item => exportColumns.map(col => item[col.key]));

        const worksheet = XLSX.utils.aoa_to_sheet([headers, ...excelData]);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, `${filename}-${currentDate}.xlsx`);
    };

    const handlePdfExport = () => {
        const doc = new jsPDF();
        doc.autoTable({
            head: [exportColumns.map(col => col.label)],
            body: data.map(item => exportColumns.map(col => item[col.key])),
        });
        doc.save(`${filename}-${currentDate}.pdf`);
    };

    const handleWordExport = () => {
        const tableRows = data.map(item =>
            new TableRow({
                children: exportColumns.map(col =>
                    new TableCell({
                        children: [new Paragraph(item[col.key]?.toString() || '')],
                    })
                ),
            })
        );

        const table = new Table({
            rows: [
                new TableRow({
                    children: exportColumns.map(col =>
                        new TableCell({
                            children: [new Paragraph(col.label)],
                        })
                    ),
                }),
                ...tableRows,
            ],
        });

        const doc = new Document({
            sections: [
                {
                    children: [table],
                },
            ],
        });

        Packer.toBlob(doc).then(blob => {
            saveAs(blob, `${filename}-${currentDate}.docx`);
        });
    };

    const exportMenu = (
        <Menu className="dropdown">
            <Menu.Item key="csv">
                <CSVLink
                    filename={`${filename}-${currentDate}.csv`}
                    data={data}
                    headers={exportColumns}
                    className="dropdown-item"
                >
                    Export CSV
                </CSVLink>
            </Menu.Item>
            <Menu.Item key="excel">
                <button onClick={handleExcelExport} className="dropdown-item">Export Excel</button>
            </Menu.Item>
            <Menu.Item key="pdf">
                <button onClick={handlePdfExport} className="dropdown-item">Export PDF</button>
            </Menu.Item>
            {/* <Menu.Item key="word">
                <button onClick={handleWordExport} className="dropdown-item">Export Word</button>
            </Menu.Item> */}
        </Menu>
    );

    return (
        <Dropdown overlay={exportMenu} placement="bottomLeft">
            <Tooltip title="Export">
                <button type="submit" className="btn btn-primary w-20 mt-2 mt-xl-0" >
                    <i className="fa-solid fa-download pointer"></i>
                </button>
            </Tooltip>
        </Dropdown>
    );
};

function getCurrentDate() {
    const currentDate = new Date();
    return currentDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
}

export default ExportDropdown;
