import React, { createContext, useContext, useState } from 'react';

// Create the context
const DataDirtyContext = createContext();

// Create the provider component
export const DataDirtyProvider = ({ children }) => {
  const [isDataDirty, setIsDataDirty] = useState(false);

  return (
    <DataDirtyContext.Provider value={{ isDataDirty, setIsDataDirty }}>
      {children}
    </DataDirtyContext.Provider>
  );
};

// Custom hook to use the context
export const useDataDirty = () => {
  const context = useContext(DataDirtyContext);
  if (!context) {
    throw new Error('useDataDirty must be used within a DataDirtyProvider');
  }
  return context;
};
