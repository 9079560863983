import { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import RoutesConfig from './Route/Route';
import Lodder from "./Component/Loader";
import useInactivityTimer from "./Route/InavctivityTimer";
import { DataDirtyProvider } from "./Context/DataDirtyContext";
const App = () => {
  useInactivityTimer();
  return (
    <DataDirtyProvider>
    <Router>
      <Suspense fallback={<Lodder show={true} />}>
        <RoutesConfig />
      </Suspense>
    </Router>
    </DataDirtyProvider>
  );
}

export default App;
